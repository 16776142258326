<template>
    <div class="basicSetting">
        <div class="editContent">
            <!-- <p>基础信息</p> -->
            <div class="basicInfo">

                <el-form :model="basicRuleForm" :rules="basicRules" ref="basicRuleForm" label-width="100px"
                    class="demo-ruleForm">
                    <el-form-item label="网站名称">
                        <el-input v-model="basicRuleForm.name" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="主站点域名">
                        <el-input v-model="basicRuleForm.siteAddress" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="网站标题">
                        <el-input v-model="basicRuleForm.title" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="英语版本">
                        <!-- <el-input v-model="basicRuleForm.EnglishWeb" size="small"></el-input> -->
                        <el-switch v-model="basicRuleForm.englishWeb" >
                        </el-switch>
                    </el-form-item>
                    <el-form-item label="繁体版本">
                        <el-switch v-model="basicRuleForm.tccWeb" >
                        </el-switch>
                        <!-- <el-input v-model="basicRuleForm.TCCWeb" size="small"></el-input> -->
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitBasicForm('basicRuleForm')" size="small">提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <p class="title">图片修改</p>
            <div class="updateImg">
                <div class="item">
                    <span class="label">logo</span>
                    <!-- :action="$store.state.requestAPI + '/attachment/upload'" :data="{
      'id': uploadInfo.classify,...APIinfo
    }" multiple -->
                    <el-upload class="avatar-uploader"
                        :action="$store.state.requestAPI + `/attachment/upload?ct=${$store.state.APIinfo.ct}&sign=${$store.state.APIinfo.sign}&jpressAppId=${$store.state.APIinfo.jpressAppId}`"
                        :show-file-list="false" :data="{
                            'id': 0
                        }" :on-success="handleAvatarSuccess1">
                        <img v-if="basicRuleForm.logo" :src="$store.state.requestAPI + basicRuleForm.logo" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <br />
                    <p>该网站的logo</p>
                </div>
                <div class="item">
                    <span class="label">背景图</span>
                    <el-upload class="avatar-uploader"
                        :action="$store.state.requestAPI + `/attachment/upload?ct=${$store.state.APIinfo.ct}&sign=${$store.state.APIinfo.sign}&jpressAppId=${$store.state.APIinfo.jpressAppId}`"
                        :show-file-list="false" :data="{
                            'id': 0
                        }" :on-success="handleAvatarSuccess2">
                        <img v-if="basicRuleForm.background" :src="$store.state.requestAPI + basicRuleForm.background"
                            class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
                <div class="item">
                    <span class="label">登录左侧图片</span>
                    <el-upload class="avatar-uploader"
                        :action="$store.state.requestAPI + `/attachment/upload?ct=${$store.state.APIinfo.ct}&sign=${$store.state.APIinfo.sign}&jpressAppId=${$store.state.APIinfo.jpressAppId}`"
                        :show-file-list="false" :data="{
                            'id': 0
                        }" :on-success="handleAvatarSuccess3">
                        <img v-if="basicRuleForm.login" :src="$store.state.requestAPI + basicRuleForm.login"
                            class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { updateBasicSettingsAPI, basicSettingsAPI } from '@/api/settings/basic'
export default {
    data() {
        return {
            imageUrl: '',
            basicRuleForm: {
                name: null,
                title: null,
                subTitle: null,
                logo: '',
                background: '',
                login: '',
                siteAddress: null,
                englishWeb: false,
                tccWeb: false
            },
            basicRules: {
                name: [
                    { required: true, message: '请输入必填信息', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
            }

        };
    },
    methods: {
        // 修改提交
        submitBasicForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {

                    const res = await updateBasicSettingsAPI(JSON.stringify(this.basicRuleForm));
                    console.log(res);
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            message: '操作成功！',
                            type: 'success'
                        });

                        this.getSettingsDetail();
                    } else {
                        this.$message({
                            showClose: true,
                            message: '操作失败！' + res.msg,
                            type: 'error'
                        });
                    }
                    // alert('submit!');
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 修改图片
        async submitImg() {
            const res = await updateBasicSettingsAPI(JSON.stringify(this.basicRuleForm));
            console.log(res);
            if (res.code === 200) {
                this.$message({
                    showClose: true,
                    message: '操作成功！',
                    type: 'success'
                });

                this.getSettingsDetail();
            } else {
                this.$message({
                    showClose: true,
                    message: '操作失败！' + res.msg,
                    type: 'error'
                });
            }
        },
        // 上传图片
        async handleAvatarSuccess1(res, file) {
            console.log(res)
            if (res.code === 200) {
                this.basicRuleForm.logo = res.data.path;
                this.submitImg();

            } else {
                this.$message({
                    showClose: true,
                    message: '操作失败！' + res.msg,
                    type: 'error'
                });
            }

            // this.imageUrl = URL.createObjectURL(file.raw);
        },
        // 上传图片
        handleAvatarSuccess2(res, file) {
            if (res.code === 200) {
                // this.basicRuleForm.background = this.$store.state.requestAPI + res.data.path;
                this.basicRuleForm.background = res.data.path;
                this.submitImg();

            } else {
                this.$message({
                    showClose: true,
                    message: '操作失败！' + res.msg,
                    type: 'error'
                });
            }
            // this.imageUrl = URL.createObjectURL(file.raw);
        },
        // 上传图片
        handleAvatarSuccess3(res, file) {
            if (res.code === 200) {
                this.basicRuleForm.login = res.data.path;
                this.submitImg();

            } else {
                this.$message({
                    showClose: true,
                    message: '操作失败！' + res.msg,
                    type: 'error'
                });
            }
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        // 获取设置详情
        async getSettingsDetail() {
            const res = await basicSettingsAPI();
            console.log(res);
            if (res.code === 200) {
                this.basicRuleForm = res.data;
                // this.basicRuleForm.background = this.$store.state.requestAPI + this.basicRuleForm.background;
                // this.basicRuleForm.logo = this.$store.state.requestAPI + this.basicRuleForm.logo;
                // this.basicRuleForm.login = this.$store.state.requestAPI + this.basicRuleForm.login;
            } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '详情获取失败!'
                });
            }
        }
    },
    created() {
        this.getSettingsDetail();
    }
}
</script>

<style lang="scss" scoped>
.basicSetting {
    width: 100%;

    .editContent {
        border-radius: 5px;
        width: 60%;
        margin: 0 auto;
        // border: 1px solid #eee;
        box-shadow: 0px 0px 4px 1px #ccc;
        padding: 20px;

        .title {
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
        }

        .basicInfo,
        .updateImg {
            margin: auto;
            width: 60%;
            // border-bottom: 1px solid #ccc;
        }
    }

    .item {
        // display: flex;
        margin-bottom: 15px;

        .label {
            display: inline-block;
            margin-right: 10px;
            width: 100px;
            text-align: right;
        }

        p {
            margin-top: 0px;
            color: #8c939d;
        }

        .avatar-uploader {
            display: inline-block;
        }

        .avatar-uploader-icon {

            border: 1px dashed #d9d9d9;

        }

        .avatar-uploader .el-upload {

            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }

        .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
        }

        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 178px;
            line-height: 178px;
            text-align: center;
        }

        .avatar {
            width: 178px;
            height: 178px;
            display: block;
        }
    }
}
</style>